<template>
  <div class="oci-ethos-body">
    <div class="oci-ethos-position">
        <div class="oci-ethos-box">
            <img v-if="showImage" :src=this.ethosurl[this.ethospos] alt="Your Image">
            <EthosButton :is-map="false" />
        </div>
    </div>      
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BButton,
} from 'bootstrap-vue'
import EthosButton from '@/components/ui/EthosButton.vue'
import router from '@/router'
export default {
  name: 'Ethos',
  components: {
    EthosButton,
    BRow,
    BCol,
    BCard,
    BButton,
  },    
  computed: {
    ...mapGetters({ ethosurl: 'app/getEthosURL' }),
    ...mapGetters({ ethospos: 'app/getEthosPos' }), 
    ...mapGetters({ ethoslen: 'app/getEthosLen' }), 
  },  
  data() {
    return {
      showImage: true,
      showButton: true
    }
  },
  methods: {
    hideImageAndButton() {
      this.showImage = false
      this.showButton = false
      this.$router.push('/');
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
</style>
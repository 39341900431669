<template>
  <div class="oci-ethos-button-container">
    <b-button
      type="submit"
      @click="HideEthos"
    >
      OK
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { messageBox } from '@/components/ui/MessageBox'
import swal from 'sweetalert2'
import store from '@/store'

export default {
  name: 'EthosButton',
  components: {
    BButton,
  },
  computed: {
    ...mapGetters({ ethosurl: 'app/getEthosURL' }),
    ...mapGetters({ ethospos: 'app/getEthosPos' }),
    ...mapGetters({ ethoslen: 'app/getEthosLen' }),
  },
  props: {
    isMap: {
      type: Boolean,
      required: true,
    },
    longitude: {
      type: Number,
      required: false,
    },
    latitude: {
      type: Number,
      required: false,
    },
  },
  methods: {
    HideEthos() {
      if (this.ethospos < this.ethoslen-1){
        let curpos = this.ethospos
        curpos = curpos + 1
        store.commit('app/setEthosPos', curpos);
      }else{
        this.$router.push('/');
      }
    },   
  },
}
</script>
